var site = site || {};

site.mppCompareInit = function(productsArray) {
  // Product compare nav
  var $compare = $('.product-compare-nav');
  if($compare.length){
    $('.js-compare-checkbox-wrapper').removeClass('hidden');
    var $mppCompareButton = $('.mpp-compare-button');
    var $mppCompareTooltip = $('.mpp-product-compare-tooltip');
    var $mppCheckbox = $('.mpp__product-grid input.compare-checkbox');
    var compareItemCount;
    var timeoutID;

    // hide compare tooltip on scroll
    $(window).on('scroll', function() {
      $mppCompareTooltip.hide();
    });

    $mppCompareButton.click(function(event) {
      event.preventDefault();
      if ((compareItemCount == 2) || (compareItemCount == 3)) {
        launchCompareOverlay();
      }
    });

    function fadeTooltip(fadeTime) {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      timeoutID = setTimeout(function() { $mppCompareTooltip.fadeOut();}, fadeTime);
    }

    function launchCompareOverlay() {
      var compareProductsIds = mppCheckedProductsIDs();
      var compareProducts = [];
      $(compareProductsIds).each(function(index, val) {
        compareProducts.push(_.find( productsArray, function(p){ return p.PRODUCT_ID == val; }) );
      });
      site.mppCompare(compareProducts);
    }

    // behavior upon clicking on compare checkbox
    $mppCheckbox.on('change', function() {
      compareItemCount = mppCompareItems();
      if (compareItemCount > 3) {
        $(this).attr('checked', false);
        mppCompareItems();
      }
      if ((compareItemCount < 4) && (compareItemCount > 0) && $(this).attr('checked')) {
        $mppCompareTooltip.show();
        fadeTooltip(3000);
      }
      if (!$(this).attr('checked')) {
        $mppCompareTooltip.hide();
      }
      var lastCompareProductID = $(this).val();
      var lastCompareProductData = _.find(productsArray, function(p){ return p.PRODUCT_ID == lastCompareProductID; });
      var content = $('script.inline-template[path="mpp-compare-tooltip"]').html();
      var render = Mustache.render(content, lastCompareProductData);
      $('.mpp-product-compare-tooltip').html(render);
      var $mppCompareNowButton = $('.mpp-compare-now-button');
      var $mppCompareTooltipClose = $mppCompareTooltip.find('.close-button');

      $mppCompareTooltipClose.click(function() {
        console.log('close tooltip');
        $mppCompareTooltip.hide();
      });
      
      if ((compareItemCount == 2) || (compareItemCount == 3)) {
        $mppCompareNowButton.show();
        $mppCompareNowButton.unbind('click');
        $mppCompareNowButton.click(function(event) {
          event.preventDefault();
          launchCompareOverlay();
        });
      } else {
        $mppCompareNowButton.hide();
      }
    });

    // $('.compare-checkbox-label').eq(0).trigger('click');
    // $('.compare-checkbox-label').eq(1).trigger('click');
    // $('.compare-checkbox-label').eq(2).trigger('click');

    // get array of checked product IDs
    function mppCheckedProductsIDs() {
      return $(".mpp__product-grid").find('input.compare-checkbox:checked').map(function() {
        return this.value;
      }).get();
    }

    function mppCompareItems() {
      compareCount = $(".mpp__product-grid").find('input.compare-checkbox:checked').length;
      if (compareCount > 0 && compareCount < 4) {
        $mppCompareButton.find('.compare-counter').html(compareCount);
        $mppCompareButton.show();
        compareProducts = [];
      } else if (compareCount < 1) {
        $mppCompareButton.hide();
        $mppCompareTooltip.hide();
      }
      return compareCount;
    }
  }
};

site.mppCompare = function(compareProducts) {

  var that = {
    compareProducts: compareProducts,
  };
  //console.log(that.compareProducts);
  // sort labels
  var sortLabel = function(attribute){
    var label = [];
    for (var i = 0; i < that.compareProducts.length; i++) {
      var attr = that.compareProducts[i][attribute] ;
      if(!_.isNull(attr) && !_.isUndefined(attr)){
        label.push(attr);
      }
    };
    label = _.uniq(label);
    if(label.length){
      return label[0].toLowerCase();
    }else{
      return '';
    }
  }
  that.compareProducts.ATTRIBUTE_LABEL_1 = sortLabel('ATTRIBUTE_LABEL_1');
  that.compareProducts.ATTRIBUTE_LABEL_3 = sortLabel('ATTRIBUTE_LABEL_3');
  that.compareProducts.ATTRIBUTE_LABEL_4 = sortLabel('ATTRIBUTE_LABEL_4');
  that.compareProducts.ATTRIBUTE_LABEL_5 = sortLabel('ATTRIBUTE_LABEL_5');

  $(that.compareProducts).each(function(index, compareProduct) {
    //console.log(compareProduct);
    var average = compareProduct['AVERAGE_RATING'];
    if(!_.isUndefined(average) && !_.isNull(average)){
      if (typeof site.product !== 'undefined' && typeof site.product.view !== 'undefined' && typeof site.product.view.getRatingPercentage !== 'undefined') {
        compareProduct['stars_percent'] = site.product.view.getRatingPercentage(average);
      }
    }

    if(compareProduct.shaded){
      // swatch list
      var skus = compareProduct.skus;
      compareProduct['filtered-intensity-show'] = 0;
      var filtered_intensity = [];
      compareProduct['color-family-show'] = 0;
      var filtered_color_family = []

      $(skus).each(function(index, sku) {
        //intensity
        if( !_.isNull(sku['INTENSITY']) && !_.isUndefined(sku['INTENSITY']) ){
          filtered_intensity.push(sku['INTENSITY']);
        }

        //color family
        if( !_.isNull(sku['ATTRIBUTE_COLOR_FAMILY']) && !_.isUndefined(sku['ATTRIBUTE_COLOR_FAMILY']) ){
          filtered_color_family.push(sku['ATTRIBUTE_COLOR_FAMILY'])
        }

        //hex
        var shadeHex = sku.HEX_VALUE_STRING;
        // explode
        var shadeHex = shadeHex.split(',');
        if(shadeHex.length == 1) {
          sku['SWATCH_TYPE'] = 'single';
          sku['HEX_VALUE_1'] = shadeHex[0];
        }else if(shadeHex.length == 2){
          sku['SWATCH_TYPE'] = 'duo';
          sku['HEX_VALUE_1'] = shadeHex[0];
          sku['HEX_VALUE_2'] = shadeHex[1];
        }else if(shadeHex.length == 3){
          sku['SWATCH_TYPE'] = 'trio';
          sku['HEX_VALUE_1'] = shadeHex[0];
          sku['HEX_VALUE_2'] = shadeHex[1];
          sku['HEX_VALUE_3'] = shadeHex[2];
        }else if(shadeHex.length == 5){
          sku['SWATCH_TYPE'] = 'quint';
          sku['HEX_VALUE_1'] = shadeHex[0];
          sku['HEX_VALUE_2'] = shadeHex[1];
          sku['HEX_VALUE_3'] = shadeHex[2];
          sku['HEX_VALUE_4'] = shadeHex[3];
          sku['HEX_VALUE_5'] = shadeHex[4];
        }
      }); // skus

      // intensity
      if ( skus.length > 1 ){
        filtered_intensity = _.uniq(filtered_intensity);
        if( filtered_intensity.length > 1 ) {
          compareProduct['filteredIntensity'] = filtered_intensity;
          compareProduct['filteredIntensityShow'] = 1;
        }
      }

      // color family
      filtered_color_family = _.uniq(filtered_color_family);
      if(filtered_color_family.length > 1){
        compareProduct['colorFamily'] = filtered_color_family;
        compareProduct['colorFamilyShow'] = 1;
      }
    }else if(!compareProduct.shaded) {
      //multiple sizes
      var multipleSizes = _.pluck(compareProduct.skus, 'PRODUCT_SIZE');
      multipleSizes = _.uniq(multipleSizes);
      multipleSizes = _.compact(multipleSizes).length > 1 ? true : false;
      if(compareProduct.sized && multipleSizes){
        compareProduct['multiple_sizes'] = true;
      }else if( (compareProduct.sized && !multipleSizes) || compareProduct.shaded){
        compareProduct['multiple_sizes'] = false;
      }
      //skintypes
      var st = _.filter(compareProduct.skus, function(sku){ return sku.SKIN_TYPE; });
      var skintypes = [];
      $(st).each(function(index, val) {
        skintypes[index] = [val.SKIN_TYPE, val.SKU_BASE_ID];
      });
      if(skintypes.length > 1){
        var skintypeKey = [];
        for (var i = 0; i < skintypes.length; i++) {
          if(skintypes[i][0] != 0){
            skintypeKey.push(skintypes[i][0]);
          }
        }
        skintypeKey = _.uniq(skintypeKey);
        var skintypeKeys = [];
        for (var i = 0; i < skintypeKey.length; i++) {
          skintypeKeys.push([skintypeKey[i],'-']);
        }
        for (var x = 0; x < skintypes.length; x++) {
          for (var i = 0; i < skintypeKeys.length; i++) {
            if(skintypes[x][0] == skintypeKeys[i][0]) {
              skintypeKeys[i][1] = skintypeKeys[i][1] + ',' + skintypes[x][1] ;
            }
          }
        }
        for (var i = 0; i < skintypeKeys.length; i++) {
          skintypeKeys[i][1] = skintypeKeys[i][1].replace("-,","");
        }
        if( skintypeKeys.length > 1){
          for (var p = 0; p < skintypeKeys.length; p++) {
            var glue = 'SKINTYPE_' + skintypeKeys[p][0];
            compareProduct[glue] = skintypeKeys[p][1];
          }
          compareProduct['skintypes'] = 1;
        }
      }
    }

  });// each compareProduct

  // console.log('that.compareProducts');
  // console.log(that.compareProducts);
  var content = $('script.inline-template[path="mpp-compare"]').html();
  var render = Mustache.render(content, that.compareProducts);

  $.colorbox({
    html:render,
    className: 'mpp-compare__container',
    fixed: 'true',
    maxWidth: '100%',
    maxHeight: '100%',
    onLoad: function(){
      $('body').addClass('products-compare');
    },
    onComplete: function(){
      if(that.compareProducts.length == 2){
        $('.mpp-compare__table td:nth-child(4)').empty();
      }
      $(that.compareProducts).each(function(index, compareProduct) {
        site.mppCompareWire(compareProduct, index);
      });
      var $trigger = $('.print-link');
      $trigger.on('click', function(e) {
        e.preventDefault();
        window.print();
      });

      $('.mpp-compare-vba__link').off('click.compare').on('click.compare', function(event){
        $('body').removeClass('products-compare');
      });
    },
    onClosed: function(){
      $('body').removeClass('products-compare');
    },
  });
  return that;
};

site.mppCompareWire = function(compareProduct, count){
  var that = {
    compareProduct: compareProduct,
  };
  var $compareContainer = $('.mpp-compare__bag-container--' + count);
  var $priceContainer = $('.mpp-compare__price-container--' + count);
  var isShaded = that.compareProduct.shaded;

  // add button and inventory
  var $compareButton = $(".js-add-to-cart", $compareContainer);
  var addBtn = site.createAddButton($(".js-add-to-cart", $compareContainer));
  addBtn.updateInvStatus();
  var $invStatusList = $(".js-inv-status-list", $compareContainer);
  var invStatusList = site.productView.InvStatusList($invStatusList);
  invStatusList.updateInvStatus();

  $compareButton.click(function(event) {
    $.colorbox.close();
    $('body').removeClass('products-compare');
  });

  $('.mpp-compare-vba__link').click(function(event) {
    $('body').removeClass('products-compare');
  });

  if(isShaded){
    var shadePicker = new site.ShadePicker(that.compareProduct);
    var $swatchSelect = $('.mpp-compare__swatch-select', $compareContainer);
    var $shadeList = $('.shade-list', $compareContainer);
    var $shadeListSwatches = $('.shade-list .swatch', $compareContainer);

    if($swatchSelect.length > 0){
      // bestsellers
      that.bestSellingSkus = that.compareProduct.BEST_SELL_SKU_BASE_ID;
      function bestSellersSort(){
        $shadeListSwatches.parent().hide();
        $(that.bestSellingSkus).each(function( index, sku) {
          var $swatch = $shadeListSwatches.filter("[data-sku-base-id=" + sku +  "]").parent();
          $swatch.show();
        });
      }
      if(!_.isUndefined(that.bestSellingSkus) && !_.isNull(that.bestSellingSkus)){
        $('.mpp-compare__all-shades', $compareContainer).remove();
        that.bestSellingSkus = that.bestSellingSkus.split(',');
        //console.log(that.bestSellingSkus);
        bestSellersSort();
        var firstBestSku = that.bestSellingSkus[0];
        $shadeListSwatches.filter(":visible").eq(0).trigger('click');
      }else{
        $('.mpp-compare__top-shades', $compareContainer).remove();
        $shadeListSwatches.eq(0).trigger('click');
      }

      // colorSelect
      //$swatchSelect.selectBox({mobile: true});
      site.mppCompareFixedBox($swatchSelect);
      function updateSwatchSelect(select){
        $(select).selectBox('refresh');
        var $select = $(select).selectBox('control');
        var $selectOption =  $($select).data('selectBox-options');
        var $selectMenuOptions = $('li a', $selectOption);
        // add shade divs to custom select
        $selectMenuOptions.each(function() {
          var swatchClass = $(this).attr('rel');
          swatchClass = 'filter-' + swatchClass.toLowerCase().replace(/ /g,"_");
          swatchClass = swatchClass.toLowerCase();
          $(this).prepend( '<div class="filtered-swatch ' + swatchClass + '"></div>');
        });
      }
      updateSwatchSelect($swatchSelect);

      $swatchSelect.on('change', function(event) {
        updateSwatchSelect($(this));
        if(!$(this)[0].selectedIndex){
          if( $('.mpp-compare__top-shades', $(this)).length ){
            bestSellersSort();
          }else{
            $shadeListSwatches.parent().show();
            $shadeListSwatches.eq(0).trigger('click');
          }
        }else{
          var swatchFilter = $(this).val();
          //test against skus for matches
          // If color family get ATTRIBUTE_COLOR_FAMILY, else if intensity get INTENSITY
          if( $(this).hasClass('color-family') ){
            var swatchFilterSkus = _.filter(that.compareProduct.skus, function(s){ return s.ATTRIBUTE_COLOR_FAMILY == swatchFilter; });
          }else if ( $(this).hasClass('intensity') ){
            var swatchFilterSkus = _.filter(that.compareProduct.skus, function(s){ return s.INTENSITY == swatchFilter; });
          }
          $shadeListSwatches.parent().hide();
          $(swatchFilterSkus).each(function( index, sku) {
            var skuID = sku.SKU_BASE_ID;
            var $swatch = $shadeListSwatches.filter("[data-sku-base-id=" + skuID +  "]").parent();
            $swatch.show();
          });
          //select first swatch in list
          $shadeListSwatches.filter("[data-sku-base-id=" + swatchFilterSkus[0].SKU_BASE_ID + "]").trigger('click');
        }
      });
    }else{
      $shadeListSwatches.eq(0).trigger('click');
    }
    $shadeListSwatches.click(function(event) {
      var shadename = $(this).attr('name');
      $('.mpp-compare__shadename', $compareContainer).html(shadename);
    });
  }else if(!isShaded){
    // size and skintype
    var $sizeSelect = $('.mpp-compare__price-size-select', $priceContainer);
    var $skintypeSelect = $('.mpp-compare__skintype-select', $compareContainer);
    if($sizeSelect.length && $skintypeSelect.length){
      function updateSizeSelect(){
        $('option', $sizeSelect).prop('disabled', true);
        var selectedSkus = $skintypeSelect.find('option:selected').attr('data-skus').split(',');
        $(selectedSkus).each(function(index, val) {
          var $option = $('option', $sizeSelect).filter("[data-sku-base-id=" + val +  "]");
          $option.prop('disabled', false);
          if(index == 0){
            $option.prop('selected', true);
          }
        });
        $sizeSelect.trigger('change');
      }
      updateSizeSelect();
    }
    if($sizeSelect.length){ site.mppCompareFixedBox($sizeSelect); }
    if($skintypeSelect.length){ site.mppCompareFixedBox($skintypeSelect); }

    $sizeSelect.on('change', function(event) {
      var selectedSku = $(this).find('option:selected').attr('data-sku-base-id');
      var skuData = _.find(that.compareProduct.skus, function(sku){ return sku.SKU_BASE_ID== selectedSku; });
      site.skuSelect(skuData);
    });

    // only use skintype as reference, set sku from size select
    $skintypeSelect.on('change', function(event) {
      if($sizeSelect.length){
        updateSizeSelect();
        $sizeSelect.selectBox('refresh');
      }else{
        var selectedSku = $(this).find('option:selected').attr('data-skus');
        //console.log(selectedSku);
        var skuData = _.find(that.compareProduct.skus, function(sku){ return sku.SKU_BASE_ID== selectedSku; });
        site.skuSelect(skuData);
      }
    });
  }
};

site.mppCompareFixedBox = function(select){
  var self = this;
  var $select = $(select);
  if($select.length){
    $select.selectBox({ mobile: true }).on('open', function(){
      // add back in when once the rest is done

      //var $selectMenu = $(select+'-selectBox-dropdown-menu');
      // var scrollTop = $(window).scrollTop();
      // var menuTop = $selectMenu.css('top').split('px')[0];
      // var fixedTop = parseInt(menuTop - scrollTop) + 'px';
      // $selectMenu.css({
      //   'position':'fixed',
      //   'top': fixedTop,
      //   //'opacity': 1
      // });
    });
    var selectControl = $select.selectBox('instance');
    $('#cboxLoadedContent').on('scroll', function() {
      selectControl.hideMenus();
    });
    window.onscroll = function (event) {
      //console.log('scrolling');
      selectControl.hideMenus();
    }
  }
};
